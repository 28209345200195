<template>
	<div class="pui-form">
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<imofalformsform-header v-model="model"></imofalformsform-header>
		</pui-form-header>
		<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#imofalform7menu'">{{ $t('imofalform7menu.maintab') }}</v-tab>
				<v-tab v-if="!isCreatingElement" :key="1" :href="'#imofalform7tab'">{{ $t('imofalform7.maintab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'imofalform7menu'">
					<v-row class="pui-form-layout">
						<v-col xs12 lg12>
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										v-if="this.isCreatingElement"
										:id="`imofalform7menu-portcall`"
										attach="imofalform7menu-portcall"
										:label="$t('imofalform7menu.portcall')"
										:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid' || this.$route.params.parentpk"
										toplabel
										clearable
										v-model="model"
										:modelName="isCreatingElement ? 'vlupportcalls' : 'vlupportcalls'"
										:modelFormMapping="{ portcallid: 'portcallid' }"
										:itemsToSelect="[{ portcallid: model.portcallid }]"
										:itemValue="['portcallid']"
										:itemText="(item) => `${item.visitid}`"
										:order="{ portcallid: 'desc' }"
										:fixedFilter="filterPortcalls"
									></pui-select>
									<pui-select
										v-if="!this.isCreatingElement"
										:id="`imofalform7menu-portcall`"
										attach="imofalform7menu-portcall"
										:label="$t('imofalform7menu.portcall')"
										disabled
										toplabel
										clearable
										v-model="model"
										:modelName="isCreatingElement ? 'vlupportcalls' : 'vlupportcalls'"
										:modelFormMapping="{ portcallid: 'portcallid' }"
										:itemsToSelect="[{ portcallid: model.portcallid }]"
										:itemValue="['portcallid']"
										:itemText="(item) => `${item.visitid}`"
										:order="{ portcallid: 'desc' }"
										:fixedFilter="filterPortcalls"
									></pui-select>
								</v-col>
								<v-col class="col-6 col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" style="padding-top: 20px; margin-left: 50px">
								</v-col>

								<v-col class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="padding-top: 20px; margin-left: -50px">
									<v-radio-group
										v-if="this.isCreatingElement"
										v-model="model.indarrivaldeparture"
										:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
										row
										style="position: relative; top: 4px"
									>
										<v-radio
											:label="$t('imofalform7menu.isarrival')"
											:checked="selectedOption === $t('imofalform7menu.isarrival')"
											:value="'1'"
										></v-radio>
										<v-radio
											:label="$t('imofalform7menu.isdeparture')"
											:checked="selectedOption === $t('imofalform7menu.isdeparture')"
											:value="'2'"
										></v-radio>
									</v-radio-group>
									<v-radio-group
										v-if="!this.isCreatingElement"
										v-model="model.indarrivaldeparture"
										disabled
										row
										style="position: relative; top: 4px"
									>
										<v-radio
											:label="$t('imofalform7menu.isarrival')"
											:checked="selectedOption === $t('imofalform7menu.isarrival')"
											:value="'1'"
										></v-radio>
										<v-radio
											:label="$t('imofalform7menu.isdeparture')"
											:checked="selectedOption === $t('imofalform7menu.isdeparture')"
											:value="'2'"
										></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-row dense style="margin-top: -15px">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('menufieldset.ship')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`imofalform7menu-shipname`"
													v-model="model.portcall.mship.shipname"
													:label="$t('imofalform7menu.shipname')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`imofalform7menu-imo`"
													v-model="model.portcall.mship.imo"
													:label="$t('imofalform7menu.imo')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`imofalform7menu-callsign`"
													v-model="model.portcall.mship.callsign"
													:label="$t('imofalform7menu.callsign')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform7menu-flag`"
													attach="imofalform7menu-flag"
													:label="$t('imofalform7menu.flag')"
													toplabel
													required
													disabled
													clearable
													reactive
													v-model="model.portcall.mship.flag"
													modelName="mcountries"
													:modelFormMapping="{ countrycode: 'flag' }"
													:itemsToSelect="itemsToSelectFlag"
													:itemValue="['countrycode']"
													:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
													:order="{ countrydesc: 'asc' }"
												></pui-select>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<v-row dense style="margin-top: -15px">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('menufieldset.voyage')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`imofalform7menu-voyagenum`"
													v-model="model.portcall.voyagenum"
													:label="$t('imofalform7menu.voyagenum')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col v-if="this.model.isarrival === '1'" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<!--
												<pui-select
													id="`imofalform7menu-portofarrival`"
													attach="imofalform7menu-portofarrival"
													:label="this.labelportarrival"
													toplabel
													v-model="model.portcall"
													modelName="mports"
													disabled
													reactive
													:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode' }"
													:itemsToSelect="itemsToSelectArrivalPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
												-->
												<pui-select
													id="`imofalform7menu-portofarrival`"
													attach="imofalform7menu-portofarrival"
													:label="this.labelportarrival"
													toplabel
													reactive
													disabled
													v-model="model.portcall"
													modelName="mports"
													:modelFormMapping="{ countrycode: 'previouscountry', portcode: 'previousport' }"
													:itemsToSelect="itemToSelectPreviousPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col
												v-if="this.model.isdeparture === '1'"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-select
													id="`imofalform7menu-portofarrival`"
													attach="imofalform7menu-portofarrival"
													:label="this.labelportarrival"
													toplabel
													v-model="model.portcall"
													modelName="mports"
													disabled
													reactive
													:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode' }"
													:itemsToSelect="itemsToSelectArrivalPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col v-if="this.model.isarrival === '1'" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<!--
												<pui-select
													id="`imofalform7menu-lastport`"
													attach="imofalform7menu-lastport"
													:label="$t('imofalform7menu.lastport')"
													toplabel
													reactive
													disabled
													v-model="model.portcall"
													modelName="mports"
													:modelFormMapping="{ countrycode: 'previouscountry', portcode: 'previousport' }"
													:itemsToSelect="itemToSelectPreviousPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
													-->
												<pui-select
													id="`imofalform7menu-lastport`"
													attach="imofalform7menu-lastport"
													:label="$t('imofalform7menu.lastport')"
													toplabel
													v-model="model.portcall"
													modelName="mports"
													disabled
													reactive
													:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode' }"
													:itemsToSelect="itemsToSelectArrivalPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col
												v-if="this.model.isdeparture === '1'"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-select
													id="`imofalform7menu-nextport`"
													attach="imofalform7menu-nextport"
													:label="$t('imofalform7menu.nextport')"
													toplabel
													reactive
													v-model="model.portcall"
													disabled
													modelName="mports"
													:modelFormMapping="{ countrycode: 'nextcountry', portcode: 'nextport' }"
													:itemsToSelect="itemToSelectNextPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<!--<v-col>
												<pui-select
													id="`imofalform7menu-shiptype`"
													attach="imofalform7menu-shiptype"
													:label="$t('imofalform7menu.shiptype')"
													toplabel
													clearable
													disabled
													reactive
													v-model="model.portcall.mship"
													modelName="mshiptypes"
													:modelFormMapping="{ shiptypecode: 'shiptypecode' }"
													:itemsToSelect="itemsToSelectShipType"
													:itemValue="['shiptypecode']"
													:itemText="(item) => `${item.shiptypedesc}`"
													:order="{ shiptypedesc: 'asc' }"
												></pui-select>
											</v-col>
										<v-col v-if="this.model.isarrival === '1'" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
									<pui-date-field
										:id="`imofalform7menu-arrivaldate`"
										:label="$t('imofalform7menu.arrivaldate')"
										toplabel
										time
										v-model="model.arrivaldate"
										disabled
									></pui-date-field>
								</v-col>
								<v-col v-if="this.model.isdeparture === '1'" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
									<pui-date-field
										:id="`imofalform7menu-departuredate`"
										:label="$t('imofalform7menu.departuredate')"
										toplabel
										time
										v-model="model.departuredate"
										disabled
									></pui-date-field>
								</v-col>
										-->
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="1" :value="'imofalform7tab'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="imofalform7-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ imofalformid: 'imofalformid' }"
						:parentModel="model"
						:formDisabled="this.model.status === this.statusSubmitted || this.model.status === 'Invalid'"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<pui-form-footer>
				<nsw-imo-form-footer-btns
					:formDisabled="(this.model.status == 'Invalid' && !isCreatingElement) || !havePermissions(['WRITE_IMOFALFORM7'])"
					:saveDisabled="saving"
					:value="model"
					:saveAndUpdate="saveAndUpdate"
					:showNext="isCreatingElement"
					:showDraft="!isCreatingElement && this.model.status !== this.statusSubmitted"
					:showSubmit="!isCreatingElement && this.model.status !== this.statusSubmitted"
					:showNewVersion="false"
					modelName="imofalform7menu"
					:save="save"
					:back="back"
				></nsw-imo-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { STATUS_SUBMITTED_DESC } from '../../constants/imofalforms/ImoFalFormsConstants';
import imofalform7Actions from './ImoFalForm7Actions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'imofalform7menuform',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'imofalform7menu',
			tabmodel: 'imofalform7menu',
			pkAttribute: 'imofalformid',
			actions: imofalform7Actions.actions,
			labelportarrival: this.$t('imofalform7menu.portofarrival'),
			statusSubmitted: STATUS_SUBMITTED_DESC
		};
	},
	mounted() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-imofalform7menu-portcall', (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel !== null) {
				this.fillPortCallFields(lupaModel);
			} else {
				this.clearPortCallFields();
			}
		});
	},
	computed: {
		filterPortcalls() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'portcallstatus', op: 'ne', data: 0 },
							{ field: 'portcallstatus', op: 'ne', data: 6 },
							{ field: 'portcallstatus', op: 'ne', data: 3 }
						]
					}
				]
			};
		},
		itemsToSelectShipType() {
			return [{ shiptypecode: this.model.portcall.mship.shiptypecode }];
		},
		itemsToSelectArrivalPort() {
			return [{ countrycode: this.model.portcall.countrycode, portcode: this.model.portcall.portcode }];
		},
		itemsToSelectFlag() {
			return [{ countrycode: this.model.portcall.mship.flag }];
		},
		itemToSelectPreviousPort() {
			return [{ countrycode: this.model.portcall.previouscountry, portcode: this.model.portcall.previousport }];
		},
		itemToSelectNextPort() {
			return [{ countrycode: this.model.portcall.nextcountry, portcode: this.model.portcall.nextport }];
		}
	},
	watch: {
		'model.isarrival'() {
			if (this.model.isarrival === '1') {
				this.model.isdeparture = '0';
				this.model.indarrivaldeparture = '1';
				this.labelportarrival = this.$t('imofalform7menu.portofarrival');
			}
		},

		'model.isdeparture'() {
			if (this.model.isdeparture === '1') {
				this.model.isarrival = '0';
				this.model.indarrivaldeparture = '2';
				this.labelportarrival = this.$t('imofalform7menu.portofdeparture');
			}
		}
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			if (this.$route.params.parentpk) {
				this.model.portcallid = JSON.parse(atob(this.$route.params.parentpk)).portcallid;
			}
		},
		fillPortCallFields(lupaModel) {
			this.model.portcall.mship.shipname = lupaModel.shipname;
			this.model.portcall.mship.shiptypecode = lupaModel.shiptypecode;
			this.model.portcall.mship.imo = lupaModel.imo;
			this.model.portcall.mship.callsign = lupaModel.callsign;
			this.model.portcall.voyagenum = lupaModel.voyagenum;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.portcode = lupaModel.portcode;
			this.model.arrivaldate = lupaModel.ata !== null ? lupaModel.ata : lupaModel.eta;
			this.model.departuredate = lupaModel.atd !== null ? lupaModel.atd : lupaModel.etd;
			this.model.portcall.mship.flag = lupaModel.flag;
			this.model.portcall.previouscountry = lupaModel.previouscountry;
			this.model.portcall.previousport = lupaModel.previousport;
			this.model.portcall.nextcountry = lupaModel.nextcountry;
			this.model.portcall.nextport = lupaModel.nextport;
			this.model.portcall.mship.flag = lupaModel.flag;
		},
		clearPortCallFields() {
			this.model.portcall.mship.shipname = null;
			this.model.portcall.mship.shiptypecode = null;
			this.model.portcall.mship.imo = null;
			this.model.portcall.mship.callsign = null;
			this.model.portcall.voyagenum = null;
			this.model.portcall.countryofarrival = null;
			this.model.portcall.countryofdeparture = null;
			this.model.portcall.portofarrival = null;
			this.model.portcall.portofdeparture = null;
			this.model.arrivaldate = null;
			this.model.departuredate = null;
			this.model.portcall.countrycode = null;
			this.model.portcall.portcode = null;
			this.model.portcall.previouscountry = null;
			this.model.portcall.previousport = null;
			this.model.portcall.nextcountry = null;
			this.model.portcall.nextport = null;
		}
	},

	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-imofalform7menu-portcall');
	}
};
</script>
